<template>
  <path
    d="M16.563 0A2.437 2.437 0 0 1 19 2.437v12.108a2.437 2.437 0 0 1-2.437 2.437H11.69l-1.895 1.895a.42.42 0 0 1-.534.049l-.058-.049-1.896-1.895h-4.87a2.437 2.437 0 0 1-2.434-2.303L0 14.545V2.437A2.437 2.437 0 0 1 2.437 0zm0 .838H2.437c-.883 0-1.599.716-1.599 1.6v12.107c0 .883.716 1.599 1.6 1.599h5.044a.42.42 0 0 1 .296.123l1.722 1.72 1.722-1.72a.42.42 0 0 1 .23-.118l.066-.005h5.045c.883 0 1.599-.716 1.599-1.6V2.438c0-.883-.716-1.599-1.6-1.599zM9.97 7.944l.066.058c.28.28.3.715.062 1.015l-.06.066a.763.763 0 1 1-.068-1.14zm3.824 0 .066.058c.28.28.299.715.062 1.015l-.06.066a.763.763 0 1 1-.068-1.14zm-7.647 0 .066.058.061.069c.22.276.22.67 0 .946l-.059.066a.763.763 0 1 1-.068-1.14z"
    :fill="color"
    fill-rule="nonzero"
  />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
